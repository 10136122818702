// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ajuda-js": () => import("./../src/pages/ajuda.js" /* webpackChunkName: "component---src-pages-ajuda-js" */),
  "component---src-pages-area-competencia-js": () => import("./../src/pages/area-competencia.js" /* webpackChunkName: "component---src-pages-area-competencia-js" */),
  "component---src-pages-contato-js": () => import("./../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-curriculo-js": () => import("./../src/pages/curriculo.js" /* webpackChunkName: "component---src-pages-curriculo-js" */),
  "component---src-pages-formularios-js": () => import("./../src/pages/formularios.js" /* webpackChunkName: "component---src-pages-formularios-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quem-somos-js": () => import("./../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-servicos-js": () => import("./../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-terms-js": () => import("./../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

