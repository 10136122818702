import React from "react";
import Logo from "../images/cartorio-logo.png";
import { Link } from "gatsby";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="bottom-0 w-full font-Cabin">
            <div className="bg-azul_base p-3 lg:px-6 lg:py-5">
                <div className="container mx-auto flex flex-col items-center lg:flex-row lg:justify-between">
                    <div className="flex items-center flex-shrink-0 text-white mb-6 w-32 lg:mb-0">
                        <Link to="/">
                            <img src={Logo} alt="Logotipo Cartório" />
                        </Link>
                    </div>
                    <div className="text-center text-white lg:text-right">
                        <a
                            className="px-8 lg:px-0"
                            href="https://goo.gl/maps/BaDDiU5PNb7jCaeP7"
                            target="_blank"
                            rel="noopener noreferrer">
                            <strong>Endereço</strong>: Av. Jabaquara, 1336 - Mirandópolis, São Paulo - SP, 04046002
                        </a>
                        <p className="px-8 lg:px-0">
                            <strong>Funcionamento</strong>: Seg-Sex 9:00-17:00 | Sábados 9:00-12:00
                        </p>
                        <p>
                            <strong>Telefone</strong>: <a href="tel:1155859945">(11) 5585-9945</a> | <a href="tel:1155859822">(11) 5585-9822</a>
                        </p>
                        <p>
                            <strong>
                                <FontAwesomeIcon icon={faWhatsapp} />
                            </strong> <a href="https://encurtador.com.br/mIM47">(11)5585-0112</a>
                        </p>
                        <p>
                            <strong>E-mail</strong>: atendimento@cartoriosaude.com.br
                        </p>
                    </div>
                </div>
            </div>
            <div className="bg-azul_escuro py-1 text-white text-center">
                <a href="/terms">
                    Políticas de Privacidade
                </a>
                &nbsp;|&nbsp;
                <a href="https://topnode.com.br/" target="_blank" rel="noopener noreferrer">
                    made with love by top(node)
                </a>
            </div>
        </footer>
    );
};

export default Footer;
