import { Link } from "gatsby";
import React from "react";
import Logo from "../images/cartorio-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function Header() {
    // const [isExpanded, toggleExpansion] = useState(false);
    // const { site } = useStaticQuery(graphql`
    //     query SiteTitleQuery {
    //         site {
    //             siteMetadata {
    //                 title
    //             }
    //         }
    //     }
    // `);

    const toggleMobileMenu = () => {
        document.getElementById("mobileMenu").classList.toggle("hidden");
    };

    return (
        <>
            <div className="bg-cinza_claro px-8 py-3 hidden justify-between text-azul_base lg:flex">
                <a href="https://goo.gl/maps/BaDDiU5PNb7jCaeP7" target="_blank" rel="noopener noreferrer">
                    <strong>Endereço</strong>: Av. Jabaquara, 1336 - Mirandópolis, São Paulo - SP, 04046200
                </a>

                <p>
                    <strong>Telefone</strong>: <a href="tel:1155859945">(11)5585-9945</a>
                </p>

                <p>
                    <strong>
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </strong>{" "}
                    <a href="https://encurtador.com.br/mIM47">(11)5585-0112</a>
                </p>
                <p>
                    <strong>Funcionamento</strong>: Seg-Sex 9:00-17:00 | Sábados 9:00-12:00
                </p>
            </div>
            <header className="sticky top-0 z-10">
                <nav className="bg-azul_base py-3 px-6">
                    <div className="container mx-auto flex items-center justify-between flex-wrap ">
                        <div className="flex items-center flex-shrink-0 text-white w-3/4 lg:w-1/4">
                            <Link to="/">
                                <img className="w-1/2" src={Logo} alt="Logotipo Cartório" Style="display: inline;" />
                                <span className="text-sm sm:text-lg ml-4 inline">Registro Civil</span>
                            </Link>
                        </div>
                        <div className="block lg:hidden">
                            <button onClick={toggleMobileMenu} className="text-white p-3">
                                <svg
                                    className="fill-current h-6 w-6"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <title>Menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                                </svg>
                            </button>
                        </div>
                        <div
                            id="mobileMenu"
                            className="absolute py-5 px-10 top-full left-0 hidden w-full block flex-grow bg-cinza_claro lg:static lg:bg-azul_base lg:flex lg:items-center lg:w-auto lg:justify-end lg:py-0 lg:px-0">
                            <ul className="text-sm text-cinza_escuro text-lg text-right lg:text-white lg:flex">
                                <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/quem-somos/"
                                    >
                                        Quem somos
                                    </Link>
                                </li>
                                <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/area-competencia/"
                                    >
                                        Área de Competência
                                    </Link>
                                </li>
                                {/* <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        partiallyActive={true}
                                        activeClassName="activeNav"
                                        to="/servicos/"
                                    >
                                        Serviços
                                    </Link>
                                </li> */}
                                <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <a
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        href="https://cartoriosaude.appointlet.com/"
                                        target="_blank"
                                    >
                                        Agendamentos
                                    </a>
                                </li>
                                <li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/contato/"
                                    >
                                        Contato
                                    </Link>
                                </li>

                                {/*<li className="mb-6 lg:mb-0 lg:mr-4">
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/formularios/"
                                    >
                                        Formulários
                                    </Link>
                                </li>*/}

                                <li>
                                    <Link
                                        onClick={toggleMobileMenu}
                                        className="flex justify-end"
                                        activeClassName="activeNav"
                                        to="/curriculo/"
                                    >
                                        Currículos
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default Header;
